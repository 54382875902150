/* Fix for next/Link component */
a:not(.ignore-global-css) {
  text-decoration: none;
  color: inherit;
}

body:not(.ignore-global-css) {
  transition: background-color 0.2s ease-in-out;
}

body, html, #__next {
  height: 100%;
}

#nprogress, #nprogress > * {
  z-index: 9999 !important;
}

.slick-dots li button:before  {
  font-size: 10px;
}

.slick-dots li.slick-active button:before {
  font-size: 15px;
}